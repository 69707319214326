import React from 'react'

function NoMatchRoutes() {


    const stylePage = {
        position : "absolute",
        top:"0",
        left:"0",
        zIndex : "5",
        width:"100vw",
        height:"100vh",
        background:'white',

        fontSize:"2rem",

        display:"flex",
        justifyContent:"center",
        alignItems:"center"
    }

  return (
    <div style={stylePage}>Sorry, No Match Routes!</div>
  )
}

export default NoMatchRoutes