import React from 'react'

function SingleProduct() {
  return (
    <div className='single_item_page' style={{ marginTop: "72px" }}>

    <div className='single_item'>
      <div className="single_item_img">

        <div className="large_img">
          <img src="" alt="large image" />
        </div>

        <div className="small_img">
          <img src="" alt="small-img" />
          <img src="" alt="small-img" />
          <img src="" alt="small-img" />
          <img src="" alt="small-img" />
        </div>

      </div>

      <div className="single_item_desc"></div>
    </div>

    <div className='related_items'>

    </div>

    </div>
  );
}

export default SingleProduct