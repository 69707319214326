import React, { useState } from 'react'

// images
import bestsellingproduct from "../images/bestsellingproduct.png";
import fullLogo from "../images/full-logo2.png";
import halfLogo from "../images/logo-icon2.png";

// icons
import { ImSearch } from "react-icons/im";
import { HiUser } from "react-icons/hi";
import { MdOutlineShoppingCart } from "react-icons/md";
import { BsFillCaretDownFill } from "react-icons/bs";
import {CgClose} from "react-icons/cg"
import { MdKeyboardArrowUp, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';


function NavBar() {

    // state
    const [width, setWidth] = useState("0%")
    const [searchText, setSearchText] = useState("")

    // redux
    const dispatch = useDispatch()

    const handleSearchQuery=(searchQuery)=>{
      setSearchText(searchQuery)
      dispatch({type:"FILTERS", payload: {searchQuery}})
    }

  return (
    <div>
    <nav>
      
        <Link to="/">
          <img src={fullLogo} alt="logo" className="store_name_title store_name_lg"/>
        </Link>
        
        <Link to="/">
          <img src={halfLogo} alt="logo-mini" className="store_name_title store_name_md"/>
        </Link>

        <div className="search_bar">
          <input type="text" value={searchText} onChange={(e)=>{handleSearchQuery(e.target.value)}} />
          <span>
            <ImSearch />
          </span>
        </div>

        <p
          className="cart-icon"
          onClick={() => {
            setWidth("100%");
          }}
        >
          <MdOutlineShoppingCart />
          <span className="cart_item_count">62</span>
        </p>

        <ul className="user_icon">
          <li>
            <HiUser style={{ fontSize: "1.2rem" }} />
          </li>
          <li className="account_options">
            Account <BsFillCaretDownFill />
          </li>

          <ul className="account_dropdown">
            <li className="user_tag">
              <HiUser style={{ fontSize: "1.2rem" }} />{" "}
              <span>Welcome back, Raymond</span>
            </li>
            <li className="register_tag">Register</li>
          </ul>
        </ul>
      </nav>

      <div className="cart_side_bar" style={{ width: width }}>

        <div className="cart_side_bar_header_section">
          <span
            style={{ fontSize: "1.05rem", fontWeight: "500", color: "#333" }}
          >
            Shopping Cart
          </span>

          <span
            className="close_cart_sidebar"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setWidth("0%");
            }}
          >
            <CgClose />
          </span>
        </div>

        <div className="cart_side_bar_cart_items_sections">
          <div className="ssbci">
            <div className="ssbci_item_img_desc">
              <div className="ssbci_img">
                <img src={bestsellingproduct} alt="single item" />
              </div>
              <div className="ssbci_desc">
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: ".4em",
                  }}
                >
                  Tesla Wear
                </p>
                <p> $33.05</p>
              </div>
            </div>

            <div className="ssbci_qty">
              <span>
                <MdKeyboardArrowUp />
              </span>
              <input type="number" min={1} />
              <span>
                <MdOutlineKeyboardArrowDown />
              </span>
            </div>

            <span className="ssbci_remove_item">
              <CgClose />
            </span>
          </div>

          <div className="ssbci">
            <div className="ssbci_item_img_desc">
              <div className="ssbci_img">
                <img src={bestsellingproduct} alt="single item" />
              </div>
              <div className="ssbci_desc">
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: ".4em",
                  }}
                >
                  Tesla Wear
                </p>
                <p> $33.05</p>
              </div>
            </div>

            <div className="ssbci_qty">
              <span>
                <MdKeyboardArrowUp />
              </span>
              <input type="number" min={1} />
              <span>
                <MdOutlineKeyboardArrowDown />
              </span>
            </div>

            <span className="ssbci_remove_item">
              <CgClose />
            </span>
          </div>

          <div className="ssbci">
            <div className="ssbci_item_img_desc">
              <div className="ssbci_img">
                <img src={bestsellingproduct} alt="single item" />
              </div>
              <div className="ssbci_desc">
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: ".4em",
                  }}
                >
                  Tesla Wear
                </p>
                <p> $33.05</p>
              </div>
            </div>

            <div className="ssbci_qty">
              <span>
                <MdKeyboardArrowUp />
              </span>
              <input type="number" min={1} />
              <span>
                <MdOutlineKeyboardArrowDown />
              </span>
            </div>

            <span className="ssbci_remove_item">
              <CgClose />
            </span>
          </div>

          <div className="ssbci">
            <div className="ssbci_item_img_desc">
              <div className="ssbci_img">
                <img src={bestsellingproduct} alt="single item" />
              </div>
              <div className="ssbci_desc">
                <p
                  style={{
                    fontSize: "1rem",
                    fontWeight: "500",
                    marginBottom: ".4em",
                  }}
                >
                  Tesla Wear
                </p>
                <p> $33.05</p>
              </div>
            </div>

            <div className="ssbci_qty">
              <span>
                <MdKeyboardArrowUp style={{ cursor: "pointer" }} />
              </span>
              <input type="number" min={1} />
              <span>
                <MdOutlineKeyboardArrowDown style={{ cursor: "pointer" }} />
              </span>
            </div>

            <span className="ssbci_remove_item">
              <CgClose />
            </span>
          </div>
        </div>

        <div className="cart_side_bar_bottom_element">
          <div className="subtotals">
            <span>Subtotal</span>
            <span>$35.00</span>
          </div>

          <button>VIEW CART</button>
          <button>CHECKOUT</button>
          

        </div>


      </div>

      </div>
  )
}

export default NavBar