import React  from "react";
import {BrowserRouter, Routes, Route} from "react-router-dom"

// Component
import NavBar from "./components/inc/NavBar";
import Footer from "./components/inc/Footer";
import Home from "./components/pages/Home";
import SingleProduct from "./components/pages/SingleProduct";

// styles
import "./components/styles/styles.css";
import ProductsCatalog from "./components/pages/ProductsCatalog";
import NoMatchRoutes from "./components/NoMatchRoutes";

// react-redux  
import { createStore } from "redux";
import SimpliShopReducer from "./components/reducer/SimpliShopReducer";
import { Provider } from "react-redux";

function App() {

  const store = createStore(SimpliShopReducer)

  return (
    <BrowserRouter>
      <div className="simpli_shop_app">
        <Provider store={store}>
          <NavBar />

          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<ProductsCatalog />} />
            <Route path="/products/01" element={<SingleProduct />} />
            <Route path="*" element={<NoMatchRoutes />} />
          </Routes>

          <Footer />
        </Provider>
      </div>
    </BrowserRouter>
  );
}

export default App;
