const initialData = {
  filter: {
    searchQuery: "",
    category : "all",
    ascending: false,
    descending: false,
    minPrice: 0,
    maxPrice: 1000,
    freeShipping: false,
    ratings: 0,
  },
};

function SimpliShopReducer(state = initialData, action) {



    switch(action.type){
        case "FILTERS":
            return{
                ...state,
                filter : {...state.filter, ...action.payload}
            }
        default:{
             return state
        }
    }
 
}

export default SimpliShopReducer