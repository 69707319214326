import React, { useEffect, useState } from "react";
import axios from "axios";

// icons
import { AiFillCaretDown } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import ProductsFilter from "../ProductsFilter";

function ProductsCatalog() {
  // redux
  const store = useSelector((store) => store);
  const dispatch = useDispatch()

  // filters variable
  const [productsData, setProductsData] = useState([]);
  const [productsDataSec, setProductDataSec] = useState([]);
  const filters = store.filter;
  const [categories, setCategories] = useState([])


  async function getuserData() {
    const response = await axios.get("http://localhost:7000/api/products");
    setProductsData([...response.data.getProducts]);
    setProductDataSec([...response.data.getProducts]);

    let cat = []

    //collecting products category
   response.data.getProducts.map(product=>{
    if(!cat.includes(product.category.toLowerCase())){
      cat.push(product.category)
    }
   })

   setCategories(cat)
  }

  const filterProduct=()=>{
     const filteredProducts = productsData
       .filter((product) => {
         if (filters.minPrice === "") {
           return product.price >= 0 && product.price <= filters.maxPrice;
         } else if (filters.maxPrice === "") {
           return product.price >= filters.minPrice && product.price <= 1000;
         }
         return (
           product.price >= filters.minPrice &&
           product.price <= filters.maxPrice
         );
       })

       .filter(product=>{
        if(filters.category.toLowerCase() === "all"){
          return product
        }
        else{
          return product.category.toLowerCase() === filters.category.toLowerCase()
        }
       })

       .filter((product) => {
         if (filters.freeShipping) {
           return product.freeShipping === true;
         } else {
           return product;
         }
       })

       .filter((product) => {
         if (filters.searchQuery !== "") {
           if (
             product.name
               .toLowerCase()
               .includes(filters.searchQuery.toLowerCase())
           ) {
             return product;
           }
         } else {
           return product;
         }
       })

       .sort((a, b) => {
         if (filters.ascending) {
           if (Number(a.price) < Number(b.price)) {
             return -1;
           }
         } else if (filters.descending) {
           if (Number(a.price) > Number(b.price)) {
             return -1;
           }
         }
         //else arrange the product as they come(this is by default).
       });

     setProductDataSec(filteredProducts);
  }

  const handleCategory=(e)=>{

    if(e.target.tagName.toLowerCase() === "li"){
      console.log(e.target.textContent)
      dispatch({type:"FILTERS", payload:{category :  e.target.textContent.toLowerCase()}})
    }

  }

  useEffect(() => {
    getuserData();
  }, []);

  useEffect(() => {
   filterProduct()
  }, [productsData, filters]);

  return (
    <div className="product_catalog_container" style={{ marginTop: "71px" }}>
      <ProductsFilter />

      <div className="divider"></div>

      <div className="products_container">
        <div className="catalog_header">
          <h1>Shop</h1>
          <p className="drop_down_category" style={{minWidth:"100px"}}>
            <AiFillCaretDown />
            {filters.category.slice(0, 1).toUpperCase() + filters.category.slice(1)}
            <ul onClick={handleCategory}>
              <li style={{ paddingTop: "1em" }}>All</li>
              {
                categories.map(category=>{
                  return <li>{category}</li>;
                })
              }
            </ul>
          </p>
        </div>

        <div className="best_products_flex product_catalog">
          {productsDataSec.map((product, index) => {
            return (
              <div className="single_product single_product" key={index}>
                <div className="over_lay">
                  <p className="view">View</p>
                  <p className="to_cart">Add to Cart</p>
                </div>
                <div className="flex_image">
                  <img src={product.imageUrl} alt="bestsellingproduct" />
                </div>

                <div className="nrp">
                  <p className="product_name">{product.name}</p>
                  <p className="ratings">Ratings : {product.ratings}</p>

                  <p className="product_price">£{product.price}</p>
                </div>
              </div>
            );
          })}

          {/* <div className="single_product single_product">
            <div className="flex_image">
              <img src={bestsellingproduct} alt="bestsellingproduct" />
            </div>

            <p className="product_name">Sweat Jacket</p>
            <p className="ratings">
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
            </p>

            <p className="product_price">£35.00</p>
          </div> */}
          {/* 
          <div className="single_product single_product">
            <div className="flex_image">
              <img src={bestsellingproduct} alt="bestsellingproduct" />
            </div>

            <p className="product_name">Sweat Jacket</p>
            <p className="ratings">
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
            </p>

            <p className="product_price">£35.00</p>
          </div> */}

          {/* <div className="single_product single_product">
            <div className="flex_image">
              <img src={bestsellingproduct} alt="bestsellingproduct" />
            </div>

            <p className="product_name">Sweat Jacket</p>
            <p className="ratings">
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
            </p>

            <p className="product_price">£35.00</p>
          </div>

          <div className="single_product single_product">
            <div className="flex_image">
              <img src={bestsellingproduct} alt="bestsellingproduct" />
            </div>

            <p className="product_name">Sweat Jacket</p>
            <p className="ratings">
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
            </p>

            <p className="product_price">£35.00</p>
          </div>

          <div className="single_product single_product">
            <div className="flex_image">
              <img src={bestsellingproduct} alt="bestsellingproduct" />
            </div>

            <p className="product_name">Sweat Jacket</p>
            <p className="ratings">
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
            </p>

            <p className="product_price">£35.00</p>
          </div>

          <div className="single_product single_product">
            <div className="flex_image">
              <img src={bestsellingproduct} alt="bestsellingproduct" />
            </div>

            <p className="product_name">Sweat Jacket</p>
            <p className="ratings">
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
            </p>

            <p className="product_price">£35.00</p>
          </div>

          <div className="single_product single_product">
            <div className="flex_image">
              <img src={bestsellingproduct} alt="bestsellingproduct" />
            </div>

            <p className="product_name">Sweat Jacket</p>
            <p className="ratings">
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
              <AiOutlineStar style={{ cursor: "pointer" }} />
            </p>

            <p className="product_price">£35.00</p>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ProductsCatalog;
