import React from 'react'

// icons
import { MdOutlineShoppingCart } from "react-icons/md";
import { AiOutlineStar } from "react-icons/ai";
import { TbTruckDelivery } from "react-icons/tb";
import { SiAdguard } from "react-icons/si";
import { MdOutlinePayments } from "react-icons/md";
import { RiSecurePaymentFill } from "react-icons/ri";
import { SiAmazon, SiBigcartel, SiFlutter } from "react-icons/si";
import {GrPaypal} from "react-icons/gr"
import {FaFedex} from "react-icons/fa"

// images
import bannerImg from  "../images/banner-img.png";
import bestsellingproduct from "../images/bestsellingproduct.png";
import trendingproduct from "../images/trending-product.png";
import { Link } from 'react-router-dom';


function Home() {


  return (
    <div style={{ marginTop: "70px" }}>
      {/* banner */}
      <div className="hero_section">
        <div className="hero_img">
          <img src={bannerImg} alt="sweat-jacket" />
        </div>

        <div className="hero_text">
          <p className="banner_title">Best Quality Products</p>
          <p className="banner_desc">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
            tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
          </p>

          <Link to="/products" className="banner_btn">
            <MdOutlineShoppingCart /> Shop Now
          </Link>
        </div>
      </div>

      {/* best selling Products */}
      <div className="best_selling_products home_page_bt">
        <h1 className="best_selling_header">Best Selling Products</h1>

        <div className="best_products_flex">
          <div className="single_product">
            <div className="flex_image">
              <img src={bestsellingproduct} alt="bestsellingproduct" />
            </div>

            <div className="nrp">
              <p className="product_name">Sweat Jacket</p>
              <p className="ratings">
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
              </p>

              <p className="product_price">£35.00</p>
            </div>
          </div>

          <div className="single_product">
            <div className="flex_image">
              <img src={bestsellingproduct} alt="bestsellingproduct" />
            </div>

            <div className="nrp">
              <p className="product_name">Sweat Jacket</p>
              <p className="ratings">
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
              </p>

              <p className="product_price">£35.00</p>
            </div>
          </div>

          <div className="single_product">
            <div className="flex_image">
              <img src={bestsellingproduct} alt="bestsellingproduct" />
            </div>

            <div className="nrp">
              <p className="product_name">Sweat Jacket</p>
              <p className="ratings">
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
              </p>

              <p className="product_price">£35.00</p>
            </div>
          </div>

          <div className="single_product">
            <div className="flex_image">
              <img src={bestsellingproduct} alt="bestsellingproduct" />
            </div>

            <div className="nrp">
              <p className="product_name">Sweat Jacket</p>
              <p className="ratings">
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
              </p>

              <p className="product_price">£35.00</p>
            </div>
          </div>

          <div className="single_product">
            <div className="flex_image">
              <img src={bestsellingproduct} alt="bestsellingproduct" />
            </div>

            <div className="nrp">
              <p className="product_name">Sweat Jacket</p>
              <p className="ratings">
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
              </p>

              <p className="product_price">£35.00</p>
            </div>
          </div>
        </div>
      </div>

      {/* why buy from us */}
      <div className="why_buy_from_us">
        <h1 className="why_header">Why Buy From Us ?</h1>

        <div className="benefits">
          <div className="single_benefit">
            <TbTruckDelivery
              style={{ fontSize: "2rem", color: "rgb(200,0,0)" }}
            />
            <p>
              <span className="benefit_header">Free Shipping</span>
              <span>Above $5</span>
            </p>
          </div>

          <div className="single_benefit">
            <SiAdguard style={{ fontSize: "2rem", color: "rgb(200,0,0)" }} />
            <p>
              <span className="benefit_header">Quality Products</span>
              <span>100% Guarantee</span>
            </p>
          </div>

          <div className="single_benefit">
            <RiSecurePaymentFill
              style={{ fontSize: "2rem", color: "rgb(200,0,0)" }}
            />
            <p>
              <span className="benefit_header">Secure Payment GateWay</span>
              <span>customer safety first.</span>
            </p>
          </div>

          <div className="single_benefit">
            <MdOutlinePayments
              style={{ fontSize: "2rem", color: "rgb(200,0,0)" }}
            />
            <p>
              <span className="benefit_header">Friendly Return Policy</span>
              <span>100% free pament</span>
            </p>
          </div>
        </div>
      </div>

      {/* trending products */}
      <div className="best_selling_products home_page_bt">
        <h1 className="best_selling_header">Trending Products</h1>

        <div className="best_products_flex">
          <div className="single_product">
            <div className="flex_image">
              <img src={trendingproduct} alt="bestsellingproduct" />
            </div>

            <div className="nrp">
              <p className="product_name">Sweat Jacket</p>
              <p className="ratings">
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
              </p>
              <p className="product_price">£35.00</p>
            </div>
          </div>

          <div className="single_product">
            <div className="flex_image">
              <img src={trendingproduct} alt="bestsellingproduct" />
            </div>

            <div className="nrp">
              <p className="product_name">Sweat Jacket</p>
              <p className="ratings">
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
              </p>
              <p className="product_price">£35.00</p>
            </div>
          </div>

          <div className="single_product">
            <div className="flex_image">
              <img src={trendingproduct} alt="bestsellingproduct" />
            </div>

            <div className="nrp">
              <p className="product_name">Sweat Jacket</p>
              <p className="ratings">
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
              </p>
              <p className="product_price">£35.00</p>
            </div>
          </div>

          <div className="single_product">
            <div className="flex_image">
              <img src={trendingproduct} alt="bestsellingproduct" />
            </div>

            <div className="nrp">
              <p className="product_name">Sweat Jacket</p>
              <p className="ratings">
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
              </p>
              <p className="product_price">£35.00</p>
            </div>
          </div>

          <div className="single_product">
            <div className="flex_image">
              <img src={trendingproduct} alt="bestsellingproduct" />
            </div>

            <div className="nrp">
              <p className="product_name">Sweat Jacket</p>
              <p className="ratings">
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
                <AiOutlineStar style={{ cursor: "pointer" }} />
              </p>
              <p className="product_price">£35.00</p>
            </div>
          </div>
        </div>
      </div>

      {/* customers reviews */}
      <div className="customer_reviews">
        <h1>What Buyers Say</h1>
        <p>
          tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo, mattis,
          pulvinar dapibus leo.
        </p>

        <div className="row">
          <div className="review_col">
            <img src={bannerImg} alt="" />

            <div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
              <h3>Christine Berkely</h3>
            </div>
          </div>

          <div className="review_col">
            <img src={bannerImg} alt="" />

            <div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
              <h3>Christine Berkely</h3>
            </div>
          </div>

          <div className="review_col">
            <img src={bannerImg} alt="" />

            <div>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.
              </p>
              <h3>Christine Berkely</h3>
            </div>
          </div>
        </div>
      </div>

      <div className="support_container">
        <h1>Supported By Popular Brands</h1>
        <div className="support">
          <span>
            <SiAmazon className="icon" />
          </span>
          <span>
            <SiBigcartel className="icon" />
          </span>

          <span>
            <FaFedex className="icon" />
          </span>

          <span>
            <GrPaypal className="icon" />
          </span>
          <span>
            <SiFlutter className="icon" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Home