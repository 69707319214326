import React , {useState} from "react";

import { AiOutlineStar} from "react-icons/ai";
import { useDispatch, useSelector} from "react-redux";

function ProductsFilter() {
  const dispatch = useDispatch();
  const store = useSelector((store)=>store)

  const [filter, setFilter] = useState({
    ascending: false,
    descending: false,
    minPrice: 0,
    maxPrice: 1000,
    freeShipping: false,
    ratings: 0,
  });

  //handle filters
  const handleFilter = (e) => {

    let key = e.target.name;
    let value = e.target.value;

    let d = { ...filter};   //because Reactjs state setters are by default    asynchronous ,
        d[key] = value;     //in a case like this, they ommits a character less when setting
                            //state  value,  therby  affecting  the  code  output,  but
                            //creating a regular Js variable like this to temporary take up
                            //the state value will solve the issue.
    
    setFilter(d);
    dispatch({ type: "FILTERS", payload: d });
  };

  // handle ascend and descend
  const handleAscendDescend=(id)=>{
   let d = store.filter

    if(id === "ascending"){
        d = { ...d, ascending: true };
        d = { ...d, descending: false };
    }
    else if(id === "descending"){
        d = { ...d, ascending: false };
        d = { ...d, descending: true };
    }

    console.log(d)
    setFilter(d)
    dispatch({ type: "FILTERS", payload: d });
  }

  const handleFreeShipping=(e)=>{
    let d = store.filter;

    if(e.target.checked){
      d = {...d, freeShipping : true}
      setFilter(d);
      dispatch({ type: "FILTERS", payload: d });
    }

    else if(!e.target.checked){
      d = { ...d, freeShipping: false };
      setFilter(d);
      dispatch({ type: "FILTERS", payload: d });
    }
  }


//clear filters
  const clearFilters=()=>{
    let d = {
      ascending: false,
      descending: false,
      minPrice: 0,
      maxPrice: 1000,
      freeShipping: false,
      ratings: 0,
    };
    setFilter(d);
    dispatch({ type: "FILTERS", payload: d });
    alert("Filters Cleared")
  }

  return (
    <div className="filters_container">
      <h1 className="filter_header">Filter By :</h1>

      <div className="filters_wrapper">
        <label>
          <input type="radio" name="ascend_descend" checked={filter.ascending} onChange={(e)=>{handleAscendDescend(e.target.id)}} id="ascending" /> Ascending
        </label>

        <label>
          <input type="radio" name="ascend_descend" checked={filter.descending} onChange={(e)=>{handleAscendDescend(e.target.id)}} id="descending"/> Descending
        </label>

        <span className="filter_by_price">
          <span style={{ fontWeight: "500" }}>Price : </span>
          <input
            type="number"
            placeholder="min"
            value={filter.minPrice}
            name="minPrice"
            onChange={(e) => {
              handleFilter(e);
            }}
            style={{ marginRight: ".5em" }}
          />

          <input
            type="number"
            placeholder="max"
            value={filter.maxPrice}
            name="maxPrice"
            onChange={(e) => {
              handleFilter(e);
            }}
          />
        </span>

        <label>
          <input type="checkbox" id="shipping_filter" onClick={handleFreeShipping} /> Free Shipping
        </label>

        <p>
          <span style={{ fontWeight: "500", marginRight: ".5em" }}>
            Ratings :
          </span>
          <AiOutlineStar style={{ cursor: "pointer" }} />
          <AiOutlineStar style={{ cursor: "pointer" }} />
          <AiOutlineStar style={{ cursor: "pointer" }} />
          <AiOutlineStar style={{ cursor: "pointer" }} />
          <AiOutlineStar style={{ cursor: "pointer" }} />
          <span style={{ marginLeft: ".5em" }}>& up</span>
        </p>
      </div>

      <button className="clear_filters_btn" onClick={clearFilters}>Clear Filters</button>
    </div>
  );
}

export default ProductsFilter;
